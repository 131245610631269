import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Models from '../../models/models-index';
import * as SharedServices from '../../services/services-index';
import { ReportsService } from '../../services/api/api-index';
import { PanelElementResolverService } from '../../components/panels/panel-element-resolver.service';
import { DynamicComponentDirective } from '../../directives/dynamic-component.directive';
import { RowPanelComponent } from '../panels/row-panel/row-panel.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription, combineLatest, merge } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SharedHelpTextModalComponent } from '../../modals/modals-index';
import { FilterActions } from '../../filter/store/action-types';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { AppSelectors } from '../../../_store/selector-types';
import { FilterSelectors } from '../../filter/store';


@Component({
  selector: 'report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  destroySubject = new Subject<void>();
  destroyed$ = this.destroySubject.asObservable();
  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  //viewConfig$: Observable<Models.ReportViewResponse>;
  requestModel: Models.ReportRequestModel;

  constructor(
    private resolverService: PanelElementResolverService,
    public spinner: SharedServices.SpinnerService,
    private reportService: ReportsService,
    private filterService: SharedServices.FilterService,
    private dialog: MatDialog,
    private translationService: SharedServices.TranslationService,
    private store$: Store<AppState>
  ) {

  }

  ngOnInit(): void {
    this.spinner.show();
    this.store$.select(AppSelectors.selectCurrentRouteData)
    .pipe(
      tap(_ => this.filterService.setActivePageTitle(' ')),
      switchMap(routeData =>
        this.getReportViewFilters(routeData.reportName).pipe(
          switchMap(() => this.filterService.getReportViewFilterRequestModel(routeData.reportName)),
          tap(_ => this.spinner.show()),
          switchMap(requestModel => this.getReportView(requestModel)),
          tap(_ => this.spinner.hide()),
        )
      ),
      takeUntil(this.destroyed$)
    )
    .subscribe();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  getReportViewFilters(reportName: string): Observable<Models.ReportViewFilter[]> {
    return this.reportService.getReportFilterNames(reportName).pipe(
      tap(config => this.store$.dispatch(FilterActions.updateReportViewFilterConfiguration({ config: config }))),
      switchMap(config => this.store$.select(FilterSelectors.selectReportViewFiltersForReport({ reportName })).pipe(
        filter(filters => config.filterNames.every(filterName => filters.some(f => f.name === filterName))
      ))
    ));
  }

  getReportView(requestModel: Models.ReportRequestModel): Observable<any> {
    return this.reportService.getReportView(requestModel).pipe(
      map(response => {
        this.filterService.setActivePageTitle(response.title);
        const viewContainerRef = this.dynamicComponent.viewContainerRef;
        viewContainerRef.clear();

        // get the max row number from the panels
        const maxRow = Math.max(...response.panels.map(panel => panel.row));

        // build up a report context
        const viewContext: Models.ViewContext = {
          reportName: requestModel.reportType
        };

        if (maxRow > 0) {
          // create a row for each row number
          for (let i = 1; i <= maxRow; i++) {
            const rowPanels = response.panels.filter(panel => panel.row === i)
            const componentFactory = this.resolverService.resolvePanelComponent('row-panel');
            const componentRef = viewContainerRef.createComponent(RowPanelComponent);
            componentRef.instance.rowIndex = i;
            componentRef.instance.panels = rowPanels;
            componentRef.instance.response = response;
            componentRef.instance.translationService = this.translationService;
            componentRef.instance.viewContext = viewContext;
          }
        } else {
          response.panels.forEach(panel => {
            const componentFactory = this.resolverService.resolvePanelComponent(panel.type);
            const componentRef = viewContainerRef.createComponent<Models.PanelComponent>(componentFactory);
            componentRef.instance.panelConfig = panel;
            componentRef.instance.dataSets = response.dataSets;
            componentRef.instance.translationService = this.translationService;
            componentRef.instance.viewContext = viewContext;
            //this.renderer2.setAttribute(componentRef.location.nativeElement, 'style', this.panelFormattingService.getPanelStyles(panel));
          });
        }
        return response;
      })
    );
  }

  // help
  openHelp(panelConfig: Models.Panel) {
    this.dialog.open(SharedHelpTextModalComponent, {
      width: '50vw',
      data: {
        title: panelConfig.configurations[0].helpTextTitle,
        name: panelConfig.configurations[0].helpTextKey
      }
    });
  }

  getInitialRequestModel(routeData): Models.ReportRequestModel {
    return {
      orderBy: '',
      orderAscending: false,
      reportType: routeData.reportName,
      filters: {
        startDate: new Date(),
        endDate: new Date()
      }
    };
  }
}
